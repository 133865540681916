<template>
	<section class="toast-notification">
		<transition name="fade">
			<cv-toast-notification v-if="notification.visible"
				:kind="notification.kind"
				:title="notification.title"
				:sub-title="notification.subtitle"
				:caption="notification.caption"
				@close="closeNotification()"
				:close-aria-label="notification.closeAriaLabel"
				:low-contrast="notification.lowContrast"
				:hide-close-button="notification.hideCloseButton">
			</cv-toast-notification>
		</transition>
	</section>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
	name: 'NotificationComponent',
	computed: {
		...mapGetters({
			notification: 'Notification/getNotificationProps',
		}),
	},
	methods: {
		...mapActions({
			closeNotification: 'Notification/closeNotification',
		}),
	},
};
</script>
<style lang="scss">
.toast-notification {
	position: fixed;
	z-index: 1;
	right: 8px;
	top: 40px;

}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
