import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
	namespaced: true,
	state() {
		return {
			formData: {
				brand: '',
				businessJustification: '',
				businessPartnerCompanyName: '',
				configurationFileAttachments: [],
				configurationsForLoan: [],
				configurationsId: [],
				contractType: '',
				contractTypeComments: '',
				customerAddress: '',
				customerCity: '',
				customerCountry: '',
				customerName: '',
				customerNumber: '',
				customerState: '',
				customerZipCode: '',
				days: null,
				description: '',
				distributorCompany: '',
				expectedBusinessValue: '',
				expectedRevenueRealization: '',
				installAddress: '',
				installCity: '',
				installCountry: '',
				installCustomerContactPhone: '',
				installCustomerContactName: '',
				installCustomerEmail: '',
				installCustomerName: '',
				installState: '',
				installZipCode: '',
				isConfigurationFilePresent: false,
				localCurrency: '',
				oddsClosing: '',
				opportunityNumber: '',
				opportunityOwner: '',
				isOpportunityOwnerProvidedByUser: false,
				plannedEndDate: '',
				plannedStartDate: '',
				purposeOfRequest: '',
				requestId: '',
				shipAddress: '',
				shipCity: '',
				shipCountry: '',
				shipCustomerContactPhone: '',
				shipCustomerContactName: '',
				shipCustomerEmail: '',
				shipCustomerName: '',
				shipState: '',
				shipZipCode: '',
				signerCustomerEmail: '',
				signerCustomerName: '',
				signerCustomerTitle: '',
				typeOfOffering: '',
				isOpportunityPresent: true,
			},
			isPopulatingOpportunity: false,
			errorIsPopulatingOpportunity: false,
			preventRedirect: false,
			isSubmitLoading: false,
			isVerifyMachineLoading: false,
			errorToGetDraftData: false,
		};
	},
	mutations,
	actions,
	getters,
};
