import axios from '../axios/axios';

export default {
	async getDataToPopulate(inputReferencesValues, apiReference) {
		const queryString = new URLSearchParams(inputReferencesValues);
		return axios.get(`/${apiReference}?${queryString}`);
	},
	async sendRequest(payload) {
		return axios.post('/glt/request', payload, {
			headers: { 'Content-Type': 'multipart/form-data' },
		});
	},
	async getDataFromDraftRequest(payload) {
		return axios.get(`/glt/request?id=${payload}&status=draft`);
	},
	async discardDraftRequest(payload) {
		return axios.post('/glt/request/delete', payload);
	},
	async verifyIfMachineIsAvailable(machineModel, machineType) {
		return axios.get(`/maximo/loadMachine?type=${machineType}&model=${machineModel}`);
	},
};
