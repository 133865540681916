import types from './mutations_types';

export default {
	[types.SHOW_NOTIFICATION](state, prop) {
		state.visible = true;
		state.kind = prop.kind;
		state.title = prop.title;
		state.subtitle = prop.subtitle;
		state.caption = prop.caption;
		state.closeAriaLabel = prop.closeAriaLabel;
		state.lowContrast = prop.lowContrast;
		state.hideCloseButton = prop.hideCloseButton;
		state.timeout = prop.timeout || state.timeout;
	},
	[types.HIDE_NOTIFICATION](state) {
		state.visible = false;
	},
	[types.CLOSE_NOTIFICATION](state) {
		state.visible = false;
	},
};
