import { APP_STATUSES } from '../../constants';

/**
 * Request interceptor to alter requests
 * @param {Object} config Axios configuration
 */
const requestInterceptor = (config) => config;

/**
 * Facilitate the access to returned data
 * @param {Object} response returned response from request
 */
const responseSuccessInterceptor = (response) => response.data;

/**
 * Flags whether a redirect to auth url has already been started.
 *
 * Redirecting to auth multiple times may cause an issue with the return URL if something
 * reacts to the "unauthorized" event (and they will) and moves the user to another place.
 */
let isRedirectingToAuth = false;

/**
 * Facilitate access to returned rejection
 * @param {Object} error returned rejection
 */
const responseErrorInterceptor = (error) => {
	const response = error.response || error;

	const { status, data } = response;

	if (status === APP_STATUSES.FORBIDDEN) {
		window.dispatchEvent(new Event('forbidden'));
	}

	if (status === APP_STATUSES.UNAUTHORIZED) {
		if (data && data.authenticationUrl && !isRedirectingToAuth) {
			isRedirectingToAuth = true;

			const appHost = window.location.pathname === '/authenticating' ? window.location.origin : window.location.href;
			window.location = `${data.authenticationUrl}?redirect_uri=${appHost}`;
		}

		window.dispatchEvent(new Event('unauthorized'));
	}

	return Promise.reject(response.data || response);
};

export {
	requestInterceptor,
	responseSuccessInterceptor,
	responseErrorInterceptor,
};
