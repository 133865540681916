import formService from '@/services/form/index';
import types from './mutations_types';
import formMapper from './mapper';

const actions = {
	DRAFT: 'draft',
	SUBMIT: 'submit',
};

export default {
	async loadData({ commit, state }, params) {
		const { inputReference, apiReference } = params;
		let data;
		try {
			commit(types.ERROR_IS_POPULATING_OPPORTUNITY, false);
			commit(types.IS_POPULATING_OPPORTUNITY, true);
			data = await formService.getDataToPopulate(inputReference, apiReference);

			commit(types.IS_POPULATING_OPPORTUNITY, false);
		} catch (error) {
			commit(types.IS_POPULATING_OPPORTUNITY, false);
			commit(types.CLEAN_DATA_POPULATED);

			commit(types.ERROR_IS_POPULATING_OPPORTUNITY, true);
			data = null;
		}

		if (data) {
			// This is not exactly nice for a generic function like that, but there is no good name to link things here...
			if (apiReference === 'loadOpportunity') {
				data.isOpportunityOwnerProvidedByUser = !data.isOpportunityOwnerValid;
			}

			const outputReferences = Object.keys(data);
			const formFields = Object.keys(state.formData);

			// Object.Keys() is not returning all keys of the loaded data, the opportunityNumber is missing sometimes
			if (data.opportunityNumber && !outputReferences.opportunityNumber) {
				outputReferences.push('opportunityNumber');
			}

			const validOutputReferences = outputReferences.filter((ref) => formFields.includes(ref));

			commit(types.PREVENT_REDIRECT, true);
			commit(types.DATA_TO_POPULATE, { data, fields: validOutputReferences });
		}
	},
	async sendRequest({ commit }, params) {
		const payload = new FormData();

		const form = formMapper.mapToRequest(params);

		form.configurationFileAttachments.forEach((attachment, index) => {
			payload.append(`configurationFileAttachments[${index}]`, attachment);
		});

		form.attachmentsToRemove.forEach((id, index) => {
			payload.append(`attachmentsToRemove[${index}]`, id);
		});

		form.configurationsForLoan.forEach((config, index) => {
			const configKeys = Object.keys(config);

			configKeys.forEach((key) => {
				payload.append(`configurationsForLoan[${index}][${key}]`, config[key]);
			});
		});

		form.configurationsId.forEach((config, index) => {
			const configKeys = Object.keys(config);

			configKeys.forEach((key) => {
				payload.append(`configurationsId[${index}][${key}]`, config[key]);
			});
		});

		const paramsWithoutConfig = form;
		delete paramsWithoutConfig.configurationsForLoan;
		delete paramsWithoutConfig.configurationFileAttachments;
		delete paramsWithoutConfig.configurationsId;
		delete paramsWithoutConfig.attachmentsToRemove;

		const keysParams = Object.keys(paramsWithoutConfig);
		keysParams.forEach((prop) => {
			payload.append(`${prop}`, form[`${prop}`]);
		});
		payload.append('action', actions.SUBMIT);

		let result = null;
		try {
			commit(types.IS_SUBMIT_LOADING, true);
			result = await formService.sendRequest(payload);
			commit(types.IS_SUBMIT_LOADING, false);
			commit(types.PREVENT_REDIRECT, false);
		} catch (error) {
			commit(types.IS_SUBMIT_LOADING, false);
			throw error;
		}
		return result;
	},
	cleanPopulatedFormData({ commit }) {
		commit(types.IS_POPULATING_OPPORTUNITY, false);
		commit(types.ERROR_IS_POPULATING_OPPORTUNITY, false);
		commit(types.CLEAN_DATA_POPULATED);
	},
	async loadDraftRequest({ commit, state }, draftRequest) {
		if (!draftRequest) {
			commit(types.CLEAN_DATA_POPULATED);
			throw Error('Draft Request Not Found');
		}
		commit(types.PREVENT_REDIRECT, true);
		const outputReferences = Object.keys(draftRequest);
		const formFields = Object.keys(state.formData);

		const validOutputReferences = outputReferences.filter((ref) => formFields.includes(ref));

		commit(types.DATA_TO_POPULATE, { data: draftRequest, fields: validOutputReferences });
	},
	async saveAsDraft({ commit }, params) {
		const payload = new FormData();

		const form = formMapper.mapToRequest(params);

		form.configurationFileAttachments.forEach((attachment, index) => {
			payload.append(`configurationFileAttachments[${index}]`, attachment);
		});

		form.configurationsId.forEach((config, index) => {
			payload.append(`configurationsId[${index}]`, config);
		});

		form.attachmentsToRemove.forEach((id, index) => {
			payload.append(`attachmentsToRemove[${index}]`, id);
		});

		form.configurationsForLoan.forEach((config, index) => {
			const configKeys = Object.keys(config);

			configKeys.forEach((key) => {
				payload.append(`configurationsForLoan[${index}][${key}]`, config[key]);
			});
		});

		const paramsWithoutConfig = form;
		delete paramsWithoutConfig.configurationsForLoan;
		delete paramsWithoutConfig.configurationFileAttachments;
		delete paramsWithoutConfig.configurationsId;
		delete paramsWithoutConfig.attachmentsToRemove;

		const keysParams = Object.keys(paramsWithoutConfig);
		keysParams.forEach((prop) => {
			payload.append(`${prop}`, form[`${prop}`]);
		});

		payload.append('action', actions.DRAFT);

		const result = await formService.sendRequest(payload);
		commit(types.PREVENT_REDIRECT, false);
		return result;
	},
	async discardAttachInDraft({ commit }, params) {
		const payload = new FormData();

		const form = formMapper.mapToRequest(params);

		form.configurationFileAttachments.forEach((attachment, index) => {
			payload.append(`configurationFileAttachments[${index}]`, attachment);
		});

		form.attachmentsToRemove.forEach((id, index) => {
			payload.append(`attachmentsToRemove[${index}]`, id);
		});

		form.configurationsId.forEach((config, index) => {
			payload.append(`configurationsId[${index}]`, config);
		});

		const paramsWithoutConfig = form;
		delete paramsWithoutConfig.configurationFileAttachments;
		delete paramsWithoutConfig.configurationsId;
		delete paramsWithoutConfig.attachmentsToRemove;

		const keysParams = Object.keys(paramsWithoutConfig);
		keysParams.forEach((prop) => {
			payload.append(`${prop}`, form[`${prop}`]);
		});

		payload.append('action', actions.DRAFT);

		const result = await formService.sendRequest(payload);
		commit(types.PREVENT_REDIRECT, false);
		return result;
	},
	async discardDraftRequest(context, payload) {
		const result = await formService.discardDraftRequest({ requestId: payload });
		return result;
	},
	async verifyMachine({ commit }, machineInformation) {
		const { machineModel, machineType } = machineInformation;

		let data = null;
		try {
			commit(types.IS_VERIFY_MACHINE_LOADING, true);
			data = await formService.verifyIfMachineIsAvailable(machineModel, machineType);
			commit(types.IS_VERIFY_MACHINE_LOADING, false);
		} catch (error) {
			commit(types.IS_VERIFY_MACHINE_LOADING, false);
			throw error;
		}
		return data;
	},
	changePreventRedirect({ commit }, flag) {
		commit(types.PREVENT_REDIRECT, flag);
	},
};
