export const enableCookieConsent = window.gltConfig.ENABLE_COOKIE_CONSENT?.toLocaleLowerCase() === 'true';

function parseRevenueRealization(label, value, defaultValue) {
	const defaultValueString = `${defaultValue.year}q${defaultValue.quarter}`;
	if (!value) {
		console.error(`${label} not set. Defaulting to ${defaultValueString}`);
		return defaultValue;
	}

	const parts = value.match(/(\d{4})q(\d)/i);
	if (parts.length !== 3) {
		console.error(`Invalid value for ${label}. value "${value}" doesn't match the expected format. Defaulting to ${defaultValueString}`);
	}

	return { year: parseInt(parts[1], 10), quarter: parseInt(parts[2], 10) };
}

export const minRevenueRealization = parseRevenueRealization(
	'Minimum revenue realization',
	window.gltConfig.EXPECTED_REVENUE_REALIZATION_MIN,
	{ year: 2023, quarter: 4 },
);

export const maxRevenueRealization = parseRevenueRealization(
	'Maximum revenue realization',
	window.gltConfig.EXPECTED_REVENUE_REALIZATION_MAX,
	{ year: 2027, quarter: 4 },
);

// eslint-disable-next-line import/prefer-default-export
export const APP_STATUSES = {
	UNAUTHORIZED: 401,
	FORBIDDEN: 403,
};
