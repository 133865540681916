import types from './mutations_types';

export default {
	[types.USER_INFORMATION](state, user) {
		state.name = user.name;
		state.email = user.email;
		state.serial = user.serial;
	},
	[types.SET_LOADING](state, isLoading) {
		state.loading = isLoading;
	},
	[types.AUTHENTICATION_STATE](state, authState) {
		state.authState = authState;
	},
};
