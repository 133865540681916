export default {
	/**
	 * Creates a new object formatted as a request.
	 * If data is provided, fields that are part of the actual model will be included.
	 *
	 * Use this function when preparing a request structure, since it will clear up any undesired field.
	 *
	 * @param {object | undefined} data data to fill fields
	 * @returns the new model, filled if data is provided
	 */
	mapToRequest(data) {
		return {
			requestId: data?.requestId || '',
			isOpportunityPresent: data?.isOpportunityPresent || true,
			opportunityNumber: data?.opportunityNumber || '',
			localCurrency: data?.localCurrency || '',
			oddsClosing: data?.oddsClosing || '',
			expectedRevenueRealization: data?.expectedRevenueRealization || '',
			distributorCompany: data?.distributorCompany || '',
			expectedBusinessValue: data?.expectedBusinessValue || '',
			opportunityOwner: data?.opportunityOwner || '',
			isOpportunityOwnerProvidedByUser: data?.isOpportunityOwnerProvidedByUser || false,
			customerCountry: data?.customerCountry || '',
			customerNumber: data?.customerNumber || '',
			customerName: data?.customerName || '',
			customerState: data?.customerState || '',
			customerCity: data?.customerCity || '',
			customerAddress: data?.customerAddress || '',
			customerZipCode: data?.customerZipCode || '',
			copyCustomerDataToInstallData: data?.copyCustomerDataToInstallData || 'false',
			copyCustomerDataToShipData: data?.copyCustomerDataToShipData || 'false',
			installAddress: data?.installAddress || '',
			installCustomerName: data?.installCustomerName || '',
			installCustomerEmail: data?.installCustomerEmail || '',
			installCustomerContactPhone: data?.installCustomerContactPhone || '',
			installCustomerContactName: data?.installCustomerContactName || '',
			installState: data?.installState || '',
			installCity: data?.installCity || '',
			installCountry: data?.installCountry || '',
			installZipCode: data?.installZipCode || '',
			shipAddress: data?.shipAddress || '',
			shipCustomerName: data?.shipCustomerName || '',
			shipCustomerEmail: data?.shipCustomerEmail || '',
			shipCustomerContactPhone: data?.shipCustomerContactPhone || '',
			shipCustomerContactName: data?.shipCustomerContactName || '',
			shipState: data?.shipState || '',
			shipCity: data?.shipCity || '',
			shipCountry: data?.shipCountry || '',
			shipZipCode: data?.shipZipCode || '',
			signerCustomerName: data?.signerCustomerName || '',
			signerCustomerTitle: data?.signerCustomerTitle || '',
			signerCustomerEmail: data?.signerCustomerEmail || '',
			days: data?.days || null,
			plannedStartDate: data?.plannedStartDate || '',
			plannedEndDate: data?.plannedEndDate || '',
			description: data?.description || '',
			purposeOfRequest: data?.purposeOfRequest || '',
			brand: data?.brand || '',
			typeOfOffering: data?.typeOfOffering || '',
			contractType: data?.contractType || '',
			businessJustification: data?.businessJustification || '',
			isConfigurationFilePresent: data?.isConfigurationFilePresent || false,
			configurationsForLoan: data?.configurationsForLoan || [],
			configurationsId: data?.configurationsId || [],
			configurationFileAttachments: data?.configurationFileAttachments || [],
			attachmentsToRemove: data?.attachmentsToRemove || [],
			businessPartnerCompanyName: data?.businessPartnerCompanyName || '',
			contractTypeComments: data?.contractTypeComments || '',
		};
	},
};
