import axios from '../axios/axios';

export default {
	async getAllRequests() {
		return axios.get('/glt/requests', {
			params: {
				status: 'submitted',
			},
		});
	},
	async getAllDraftRequests() {
		return axios.get('/glt/requests', {
			params: {
				status: 'draft',
			},
		});
	},
};
