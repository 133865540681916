import Vue from 'vue';
import CarbonVue from '@carbon/vue';

// Remember to import the correspondent component styles in "src/styles/_carbon.scss"
const componentsToBeImported = [
	'CvButton',
	'CvComboBox',
	'CvCheckbox',
	'CvCheckboxSkeleton',
	'CvColumn',
	'CvDataTable',
	'CvDataTableAction',
	'CvDataTableCell',
	'CvDataTableRow',
	'CvDataTableSkeleton',
	'CvDatePicker',
	'CvDropdown',
	'CvDropdownItem',
	'CvFileUploader',
	'CvForm',
	'CvGrid',
	'CvHeader',
	'CvHeaderPanel',
	'CvHeaderGlobalAction',
	'CvHeaderMenuButton',
	'CvHeaderName',
	'CvIconButton',
	'CvInlineLoading',
	'CvLink',
	'CvModal',
	'CvOverflowMenu',
	'CvOverflowMenuItem',
	'CvPagination',
	'CvProgress',
	'CvProgressStep',
	'CvRadioButton',
	'CvRadioGroup',
	'CvRow',
	'CvSkeletonText',
	'CvMultiSelect',
	'CvNumberInput',
	'CvTag',
	'CvTextArea',
	'CvTextInput',
	'CvToastNotification',
	'CvInteractiveTooltip',
	'CvTile',
	'CvAccordion',
	'CvAccordionItem',
	'CvSearch',
	'CvToggle',
	'CvBreadcrumb',
	'CvBreadcrumbItem',
	'CvLoading',
];

Vue.use(CarbonVue, componentsToBeImported);
