import userService from '../../../services/user/index';
import types from './mutations_types';
import { APP_STATUSES } from '../../../constants';
import authState from './authState';

export default {
	async getUserInfo({ commit }) {
		try {
			commit(types.SET_LOADING, true);

			const data = await userService.getUser();

			commit(types.USER_INFORMATION, data);
		} catch (error) {
			const { UNAUTHORIZED, FORBIDDEN } = APP_STATUSES;

			const isForbidden = error.status === FORBIDDEN;
			const isUnauthorized = error.status === UNAUTHORIZED;
			const isNotAccessError = !isForbidden && !isUnauthorized;

			if (isForbidden) {
				commit(types.AUTHENTICATION_STATE, authState.REQUEST_ACCESS);
				return;
			}

			if (isUnauthorized) {
				commit(types.AUTHENTICATION_STATE, authState.UNAUTHORIZED);
				return;
			}

			if (isNotAccessError) {
				commit(types.AUTHENTICATION_STATE, authState.ERROR);
			}
		} finally {
			commit(types.SET_LOADING, false);
		}
	},
};
