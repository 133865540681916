import types from './mutations_types';

export default {
	[types.DATA_TO_POPULATE](state, payload) {
		const { data, fields } = payload;

		const dataToUpdate = fields.reduce((acc, curr) => {
			if (data[curr] !== null && data[curr] !== undefined) {
				acc[curr] = data[curr];
			}

			return acc;
		}, {});

		state.formData = {
			...state.formData,
			...dataToUpdate,
		};
	},
	[types.IS_POPULATING_OPPORTUNITY](state, flag) {
		state.isPopulatingOpportunity = flag;
	},
	[types.SET_ERROR_TO_GET_DRAFT_DATA](state, flag) {
		state.errorToGetDraftData = flag;
	},
	[types.ERROR_IS_POPULATING_OPPORTUNITY](state, flag) {
		state.errorIsPopulatingOpportunity = flag;
	},
	[types.PREVENT_REDIRECT](state, flag) {
		state.preventRedirect = flag;
	},
	[types.CLEAN_DATA_POPULATED](state) {
		state.formData = {
			brand: '',
			businessPartnerCompanyName: '',
			businessJustification: '',
			configurationFileAttachments: [],
			attachmentsToRemove: [],
			configurationsForLoan: [],
			configurationsId: [],
			contractType: '',
			contractTypeComments: '',
			customerAddress: '',
			customerCity: '',
			customerCountry: '',
			customerName: '',
			customerNumber: '',
			customerState: '',
			customerZipCode: '',
			days: null,
			description: '',
			distributorCompany: '',
			expectedBusinessValue: '',
			expectedRevenueRealization: '',
			installAddress: '',
			installCity: '',
			installCountry: '',
			installCustomerContactPhone: '',
			installCustomerContactName: '',
			installCustomerEmail: '',
			installCustomerName: '',
			installState: '',
			installZipCode: '',
			isConfigurationFilePresent: false,
			localCurrency: '',
			oddsClosing: '',
			opportunityNumber: '',
			opportunityOwner: '',
			isOpportunityOwnerProvidedByUser: false,
			plannedEndDate: '',
			plannedStartDate: '',
			purposeOfRequest: '',
			requestId: '',
			shipAddress: '',
			shipCity: '',
			shipCountry: '',
			shipCustomerContactPhone: '',
			shipCustomerContactName: '',
			shipCustomerEmail: '',
			shipCustomerName: '',
			shipState: '',
			shipZipCode: '',
			signerCustomerEmail: '',
			signerCustomerName: '',
			signerCustomerTitle: '',
			typeOfOffering: '',
			isOpportunityPresent: true,
		};
	},
	[types.IS_VERIFY_MACHINE_LOADING](state, flag) {
		state.isVerifyMachineLoading = flag;
	},
	[types.IS_SUBMIT_LOADING](state, flag) {
		state.isSubmitLoading = flag;
	},
};
