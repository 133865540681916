import Ticky from '@/services/ticky';
import types from './mutations_types';

export default {
	async getToken({ commit }) {
		try {
			commit(types.SET_LOADING, true);
			commit(types.SET_TICKY_TOKEN_HAS_ERROR, false);
			const token = await Ticky.getToken();

			if (token == null) {
				commit(types.SET_TICKY_TOKEN_HAS_ERROR, true);
			} else {
				commit(types.SET_TICKY_TOKEN, token);
			}
		} catch (e) {
			commit(types.SET_TICKY_TOKEN_HAS_ERROR, true);
		} finally {
			commit(types.SET_LOADING, false);
		}
	},
};
