import requestService from '@/services/request/index';
import types from './mutations_types';

export default {
	async getAllActiveRequests() {
		const data = await requestService.getAllRequests();
		return data;
	},
	async getAllDraftRequests() {
		const data = await requestService.getAllDraftRequests();
		return data;
	},
	async setRequest({ commit }, request) {
		commit(types.SET_REQUEST, request);
	},
};
