export default {
	/**
   * User is authenticated.
   */
	OK: 'OK',
	/**
   * User is not authorized, but is probably being redirected to the SSO page
   */
	UNAUTHORIZED: 'UNAUTHORIZED',
	/**
   * Authentication error. The user is not authenticated, probably due to an application error.
   */
	ERROR: 'ERROR',
	/**
   * Authenticating flag. The user is being authenticating
   */
	AUTHENTICATING: 'AUTHENTICATING',
	/**
   * REQUEST ACCESS flag. The user is authorized but hasnt any permission or profile
   */
	REQUEST_ACCESS: 'REQUEST_ACCESS',
};
