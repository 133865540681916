<template>
	<div id="footer">
		<div class="container">
			<CvLink aria-labelledby="accessibility" :href="redirectPrivacy" target="blank" class="textCvLink">
				<span>{{ $t('workspace.footer.privacyPolicy') }}</span>
			</CvLink>
			<CvLink v-if="isCookieConsentEnabled" aria-labelledby="accessibility" target="blank" class="textCvLink" id="teconsent" />
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { enableCookieConsent } from '@/constants';

export default {
	name: 'FooterPrivacyLink',
	computed: {
		...mapState('User', {
			userEmail: (state) => state.email,
		}),
		redirectPrivacy() {
			if (this.isAnIBMEmail(this.userEmail)) {
				return 'https://w3.ibm.com/w3publisher/w3-privacy-notice/';
			}
			return 'https://www.ibm.com/us-en/privacy';
		},
		isCookieConsentEnabled() {
			if (enableCookieConsent) {
				const script = document.createElement('script');
				script.setAttribute('src', '//1.www.s81c.com/common/stats/ibm-common.js');
				script.setAttribute('async', 'async');
				document.body.appendChild(script);
			}
			return enableCookieConsent;
		},
	},
	methods: {
		isAnIBMEmail(email) {
			const trueIfIBMEmail = /@([a-z0-9.]+(?<!(ocean))\.)?ibm.com$/;
			return trueIfIBMEmail.test(email);
		},
	},
};

</script>
<style lang="scss" scoped>

.container {
	margin-top: 20px;
	padding: 20px;
	background-color: #262626;
	width: 100%;
	max-width: 960;
	color: $gray-40;
	display: block;
}

.textCvLink {
	span {
		color: $gray-40;
		text-decoration: none;
		text-decoration-color: $gray-10;
		padding: 0 1rem 0  1rem;
	}
}

.textCvLink:hover {
	color: $gray-40;
}

#teconsent{
	color: $gray-40;
}

::v-deep .truste_cursor_pointer  {
		cursor: pointer;
		text-decoration: none;
		color: $gray-40;
}
</style>
