import User from './user';
import Form from './form';
import Request from './request';
import Notification from './notification';
import Ticky from './ticky';

export default {
	Ticky,
	User,
	Form,
	Notification,
	Request,
};
