import Vue from 'vue';
import VueRouter from 'vue-router';
import { canCreateRequest } from '@/config';
import axios from '@/services/axios/axios';
import store from '@/store/index';
import mutationsTypesForm from '@/store/modules/form/mutations_types';

const routes = [
	{
		enabled: true,
		path: '/',
		name: 'workspace',
		component: () => import(/* webpackChunkName: "WorkSpace" */ '../views/WorkSpace.vue'),
	},
	{
		enabled: true,
		path: '/request',
		name: 'listRequest',
		component: () => import(/* webpackChunkName: "listRequest" */ '../views/ListRequests.vue'),
	},
	{
		enabled: true,
		path: '/my-drafts',
		name: 'listRequest',
		props: {
			type: 'draft',
		},
		component: () => import(/* webpackChunkName: "listRequest" */ '../views/ListRequests.vue'),
	},
	{
		enabled: canCreateRequest,
		path: '/request/new',
		name: 'newRequest',
		component: () => import(/* webpackChunkName: "NewRequest" */ '../views/NewRequest.vue'),
		props: true,
	},
	{
		enabled: true,
		path: '/request/:id',
		name: 'requestDetails',
		component: () => import(/* webpackChunkName: "requestDetails" */ '../views/RequestDetails.vue'),
		props: true,
		beforeEnter: async (to, from, next) => {
			try {
				const { id } = to.params;
				const request = await axios.get(`/glt/request?id=${id}`);

				if (request.status === 'submitted') {
					await store.dispatch('Request/setRequest', request);

					next();
				} else {
					await store.dispatch('Form/loadDraftRequest', request);

					next({
						path: `/${id}`,
					});
				}
			} catch (error) {
				store.commit(`Request/${mutationsTypesForm.SET_ERROR_TO_GET_DRAFT_DATA}`, true);
				next();
			}
		},
	},
	{
		enabled: true,
		path: '/:draftId',
		name: 'newRequestDraftId',
		component: () => import(/* webpackChunkName: "newRequestDraftId" */ '../views/NewRequest.vue'),
		props: true,
		beforeEnter: async (to, _from, next) => {
			try {
				const { draftId } = to.params;
				const request = await axios.get(`/glt/request?id=${draftId}`);

				if (request.status === 'draft') {
					await store.dispatch('Form/loadDraftRequest', request);
					next();
				} else {
					await store.dispatch('Request/setRequest', request);

					next({
						path: `/request/${draftId}`,
					});
				}
			} catch (error) {
				store.commit(`Form/${mutationsTypesForm.SET_ERROR_TO_GET_DRAFT_DATA}`, true);
				next();
			}
		},
	},
	{
		enabled: true,
		path: '*',
		name: 'notFound',
		redirect: '/',
	},
].filter((route) => route.enabled);

Vue.use(VueRouter);

const router = new VueRouter({
	mode: 'history',
	routes,
});

export default router;
