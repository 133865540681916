import { render, staticRenderFns } from "./FooterPrivacyLink.vue?vue&type=template&id=6a4ba83a&scoped=true"
import script from "./FooterPrivacyLink.vue?vue&type=script&lang=js"
export * from "./FooterPrivacyLink.vue?vue&type=script&lang=js"
import style0 from "./FooterPrivacyLink.vue?vue&type=style&index=0&id=6a4ba83a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a4ba83a",
  null
  
)

export default component.exports