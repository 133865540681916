import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import './carbon/components';
import './carbon/icons';
import 'carbon-components/css/carbon-components.css';
import './styles/app.scss';

Vue.config.productionTip = false;

new Vue({
	i18n,
	router,
	store,
	render: (h) => h(App),
}).$mount('#app');
