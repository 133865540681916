import types from './mutations_types';

export default {
	[types.SET_REQUEST](state, request) {
		state.request = request;
	},
	[types.SET_ERROR_TO_GET_DRAFT_DATA](state, flag) {
		state.errorToGetDraftData = flag;
	},
};
