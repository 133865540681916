<template>
	<CvHeader aria-label="Global Loan and Trials">
		<CvHeaderName prefix="IBM" @click="redirectToHome" style="cursor: pointer">
			Global Loan and Trials
		</CvHeaderName>
		<CvTag v-if="envIsQa" kind="red" label="Non production environment" />
		<template slot="header-global">
			<CvHeaderGlobalAction aria-label="Profile" @click="toggleMenu">
				<UserImage height="25px" width="25px" />
			</CvHeaderGlobalAction>
		</template>
	</CvHeader>
</template>

<script>
import UserImage from '@/components/User/UserImage.vue';
import { environment } from '@/config';

export default {
	name: 'Header-GLT',
	components: {
		UserImage,
	},
	data() {
		return {
			isMenuOpen: false,
			modal: false,
			envIsQa: false,
		};
	},
	mounted() {
		if (environment === 'qa') {
			this.envIsQa = true;
			return;
		}
		this.envIsQa = false;
	},
	methods: {
		redirectToHome() {
			if (this.$router.currentRoute.path !== '/') {
				this.$router.push({ name: 'workspace' });
			}
		},
		toggleMenu() {
			this.isMenuOpen = !this.isMenuOpen;
		},
		openModal() {
			if (this.isMenuOpen === true) {
				this.modal = true;
			}
		},
		closeModal() {
			this.modal = false;
		},
	},
};
</script>

<style lang="scss" scoped>
header {
  z-index: 9101;
}
</style>
