<template>
	<div id="app">
		<Header />
		<RouterView :key="$route.fullPath" :class="[isNewRequestPage ? '' : 'content']" />
		<Notification />
		<FooterPrivacyLink />
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Header from '@/components/Header/Header.vue';
import Notification from '@/components/Notification/NotificationComponent.vue';
import FooterPrivacyLink from '@/components/Footer/FooterPrivacyLink.vue';

export default {
	name: 'App',
	components: {
		Header,
		Notification,
		FooterPrivacyLink,
	},
	data: () => ({
		currentRoute: 'workspace',

	}),
	async created() {
		window.addEventListener('forbidden', () => {
			this.$router.push({ name: 'requestAccess' }).catch(() => {});
		});

		window.addEventListener('unauthorized', () => {
			this.$router.push({ name: 'authenticating' });
		});

		await this.getUserInfo();
	},
	// #region disclaimer: this is a temporary solution, the page new Request needs to be fixed using carbon grid
	watch: {
		$route(val) {
			this.currentRoute = val.name;
		},
	},
	computed: {
		...mapState('Ticky', {
			ticky: (state) => state.ticky.token,
		}),
		isNewRequestPage() {
			return ['newRequestDraftId', 'newRequest'].includes(this.currentRoute);
		},
	},
	async mounted() {
		await this.setTickyToken();
	},
	// #endregion
	methods: {
		...mapActions({
			getUserInfo: 'User/getUserInfo',
			getToken: 'Ticky/getToken',
		}),
		async setTickyToken() {
			await this.getToken();
			window.ticky.setToken(this.ticky.data.token);
			window.ticky.setEmail(this.userEmail);
		},
	},
};
</script>

<style lang="scss" scoped>
@import './styles/typography.scss';

#app {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: $white;
  justify-content: space-between;
}

.content {
	margin-top: $spacing-09;
	padding: $spacing-07 0;
	:first-child .cds--grid {
		display: flex;
		flex-direction: column;
		gap: $spacing-07;
	}
}

</style>
