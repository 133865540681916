import types from './mutations_types';

export default {
	showNotification({ commit, state }, params) {
		commit(types.SHOW_NOTIFICATION, params);

		setTimeout(() => {
			commit(types.HIDE_NOTIFICATION);
		}, state.timeout);
	},
	closeNotification({ commit, state }) {
		commit(types.CLOSE_NOTIFICATION, state);
	},
};
