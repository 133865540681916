import mutations from './mutations';
import actions from './actions';
import authState from './authState';
import getters from './getters';

export default {
	namespaced: true,

	state() {
		return {
			name: '',
			email: '',
			serial: '',
			loading: false,
			authState: authState.OK,
		};
	},
	mutations,
	actions,
	getters,
};
