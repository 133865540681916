import Vue from 'vue';
import Vuex from 'vuex';
import Vuelidate from 'vuelidate';
import modules from './modules';

Vue.use(Vuex);
Vue.use(Vuelidate);

export default new Vuex.Store({
	modules,
});
