import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
	namespaced: true,
	state() {
		return {
			visible: false,
			kind: 'info',
			title: '',
			subTitle: '',
			caption: '',
			close: () => {},
			closeAriaLabel: '',
			lowContrast: false,
			timeout: 5000,
			hideCloseButton: false,
		};
	},
	mutations,
	actions,
	getters,
};
