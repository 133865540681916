<template>
	<IconUserAvatar :class="avatarColor" :style="{ width, height }"/>
</template>

<script>
import VueTypes from 'vue-types';
import { IconUserAvatar } from '@/carbon/icons';

export const AVATAR_COLOR = Object.freeze({
	GRAY: 'gray',
	WHITE: 'white',
});

export default {
	name: 'UserImage',
	components: {
		IconUserAvatar,
	},
	props: {
		width: VueTypes.string.isRequired,
		height: VueTypes.string.isRequired,
		avatarColor: VueTypes.oneOf([
			AVATAR_COLOR.GRAY,
			AVATAR_COLOR.WHITE,
		]).def(AVATAR_COLOR.WHITE),
	},
	data() {
		return {
			imageBase64: null,
		};
	},
};
</script>

<style lang="scss" scoped>
.user-avatar {
  border-radius: 100%;
}

svg {
  &.white {
    fill: $white-0;
  }

  &.gray {
    fill: $gray-60;
  }
}
</style>
