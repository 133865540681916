import actions from './actions';
import mutations from './mutations';

export default {
	namespaced: true,

	state() {
		return {
			loading: false,
			ticky: {
				token: '',
				hasError: false,
			},
		};
	},
	mutations,
	actions,
};
