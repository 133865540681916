export default {
	DATA_TO_POPULATE: 'SET_DATA_TO_POPULATE',
	CLEAN_DATA_POPULATED: 'CLEAN_DATA_POPULATED',
	IS_POPULATING_OPPORTUNITY: 'SET_IS_POPULATING_OPPORTUNITY',
	PREVENT_REDIRECT: 'SET_PREVENT_REDIRECT',
	ERROR_IS_POPULATING_OPPORTUNITY: 'SET_ERROR_IS_POPULATING_OPPORTUNITY',
	IS_SUBMIT_LOADING: 'SET_IS_SUBMIT_LOADING',
	IS_VERIFY_MACHINE_LOADING: 'SET_IS_VERIFY_MACHINE_LOADING',
	SET_ERROR_TO_GET_DRAFT_DATA: 'SET_ERROR_TO_GET_DRAFT_DATA',
};
