import types from './mutations_types';

export default {
	[types.SET_LOADING](state, isLoading) {
		state.loading = isLoading;
	},
	[types.SET_TICKY_TOKEN](state, tokenData) {
		state.ticky.token = tokenData;
	},
	[types.SET_TICKY_TOKEN_HAS_ERROR](state, hasError) {
		state.ticky = {
			...state.ticky,
			hasError,
		};
	},
};
